<template>
  <ion-page>
    <ion-header>
      <MainToolbar isSubpage :title="i18n.$t('analysis.ai-eye.title')" />
    </ion-header>
    <ion-content :fullscreen="true">
      <Camera></Camera>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonHeader, IonContent } from '@ionic/vue';

import MainToolbar from '@/components/MainToolbar.vue';
import Camera from '@/components/CameraModal.vue';

import { useI18n } from "@/utils/i18n";

export default  {
  name: 'AIEye',
  components: { IonHeader, IonContent, IonPage, MainToolbar, Camera },
  setup() {
    const i18n = useI18n();

    return { i18n };
  }
}
</script>

<style scoped>
</style>
